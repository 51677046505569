import * as React from "react";
import { Color } from "../designToken";
import { Error } from "./error";
import { ErrorMessage } from "./errorMessage";

interface Props {
  setValue: any;
  placeholder?: string;
  nameAttr: string;
  value: string;
  message: string;
  status: boolean;
  validationTypes: any;
}
export const InputTextArea: React.FC<Props> = ({
  setValue,
  placeholder,
  nameAttr,
  value,
  message,
  status,
  validationTypes,
}) => {
  const setFormValue = (e) => {
    let message;
    if (e.target.validity.valueMissing) {
      message = ErrorMessage.require;
    }
    if (message) {
      setValue(nameAttr, e.target.value, message, true);
    } else {
      setValue(nameAttr, e.target.value, "", true);
    }
  };

  return (
    <>
      <style jsx>{`
        textarea {
          display: block;
          background-color: ${Color.white};
          padding: 10px;
          font-size: 16px;
          line-height: 1.8;
          width: 100%;
          border: 1px solid ${status ? "transparent" : Color.brand};
          font-weight: normal;
          color: ${Color.defaultText};
          border: solid 1px ${Color.lightText};
          border-radius: 4px;
        }
        textarea::placeholder {
          color: ${Color.lightText};
        }
        textarea:invalid {
          border: solid 1px ${status ? Color.brand : Color.lightText};
        }
        textarea:valid {
          border: solid 1px ${Color.lightText};
        }
      `}</style>
      <textarea
        value={value}
        name={nameAttr}
        placeholder={placeholder}
        rows="6"
        cols="60"
        onChange={(e) => setFormValue(e)}
        onBlur={(e) => setFormValue(e)}
        required={validationTypes.required && true}
      />
      {status && <Error text={message} />}
    </>
  );
};
