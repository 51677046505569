import * as React from "react";
import { Color, Variables } from "../designToken";
import { Error } from "./error";
import { ErrorMessage } from "./errorMessage";
import doneSvg from "../../images/done.svg";

interface Props {
  setValue: any;
  value: boolean;
  nameAttr: string;
  message: string;
  status: boolean;
  validationTypes: any;
  labelText: any;
}
export const Checkbox: React.FC<Props> = ({ setValue, value, nameAttr, message, status, labelText }) => {
  const setFormValue = (e) => {
    if (e.target.checked) {
      setValue(nameAttr, e.target.checked, "", false);
    } else {
      setValue(nameAttr, e.target.checked, ErrorMessage.policy, true);
    }
  };

  return (
    <>
      <style jsx>{`
          label {
            position: relative;
            cursor: pointer;
            font-weight: 500;
            display: inline-block;
            font-size: 19px;
          }
          label span {
            display: inline-block;
            vertical-align: middle;
          }
          label:before {
            display: inline-block;
            content: "";
            width: 25px;
            height: 25px;
            border: solid 2px ${status ? Color.brand : Color.defaultText};
            vertical-align: middle;
            margin-right: 10px;
            background-image: ${value ? `url(${doneSvg}` : `none`});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
          }
          label :global(.underLine) {
            text-decoration: underLine;
          }
          .doneWrapper {
            position: absolute;
            width: 15px;
            height: 15px;
            top: 5px;
            left: 0;
          }
          input {
            display: none;
          }
          @media (${Variables.breakPoint.sp}) {
            label {
              font-size: 15px;
            }
            label:before {
              width: 20px;
              height: 20px;
            }
          }
        `}</style>
      <label>
        <input type="checkbox" name={nameAttr} onChange={(e) => setFormValue(e)} />
        <span>{labelText}</span>
      </label>
      {status && <Error text={message} />}
    </>
  );
};
