import * as React from "react";
import { Color } from "../designToken";
import { Error } from "./error";
import { ErrorMessage } from "./errorMessage";

interface Props {
  setValue: any;
  placeholder?: string;
  nameAttr: string;
  value: string;
  message: string;
  status: boolean;
  validationTypes: any;
  type: string;
}
export const InputText: React.FC<Props> = ({
  setValue,
  placeholder,
  nameAttr,
  value,
  message,
  status,
  validationTypes,
  type,
}) => {
  const setFormValue = (e) => {
    let message;
    if (type === "text") {
      if (e.target.validity.valueMissing) {
        message = ErrorMessage.require;
      }
    } else if (type === "email") {
      if (e.target.validity.typeMismatch) {
        message = ErrorMessage.mail;
      }
      if (e.target.validity.valueMissing) {
        message = ErrorMessage.require;
      }
    }
    if (message) {
      e.target.setCustomValidity(message);
      setValue(nameAttr, e.target.value, message, true);
    } else {
      e.target.setCustomValidity("");
      setValue(nameAttr, e.target.value, "", false);
    }
  };

  return (
    <>
      <style jsx>{`
        input {
          display: block;
          background-color: ${Color.white};
          padding: 0 10px;
          font-size: 16px;
          line-height: 2;
          width: 100%;
          font-weight: normal;
          color: ${Color.defaultText};
          height: 32px;
          border-radius: 4px;
          border: solid 1px ${Color.lightText};
        }
        input::placeholder {
          color: ${Color.lightText};
        }
        input:invalid {
          border: solid 1px ${status ? Color.brand : Color.lightText};
        }
        input:valid {
          border: solid 1px ${Color.lightText};
        }
      `}</style>
      <input
        type={type || "text"}
        name={nameAttr}
        placeholder={placeholder}
        value={value}
        onChange={(e) => setFormValue(e)}
        onBlur={(e) => setFormValue(e)}
        required={validationTypes.required && true}
      />
      {status && <Error text={message} />}
    </>
  );
};
