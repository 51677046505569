import * as React from "react";
import { Color, Variables } from "../designToken";

interface Props {
  children: any;
}
const SectionBody = (props: Props) => {
  return (
    <>
      <style jsx>{`
        .section.body .sectionInner {
          max-width: 900px;
          padding: 100px 0 0;
        }
        .section.body .sectionInner {
          padding-top: 50px;
        }
        @media (${Variables.breakPoint.sp}) {
          .section.body :global(.btn) {
            margin: 30px 0 0px;
          }
        }
      `}</style>
      <section className="section body" id="sectionBody">
        <div className="sectionInner">{props.children}</div>
      </section>
    </>
  );
};

export default SectionBody;
