import * as React from "react";
import { Color, Variables } from "../../components/designToken";

interface Props {
  title: string;
  content: any;
}
export const ConfirmItem: React.FC<Props> = ({ title, content }) => {
  return (
    <>
      <style jsx>{`
        div {
          padding: 20px 0;
          border-bottom: solid 1px ${Color.lightText};
        }
        div:last-child {
          border-bottom: none;
        }
        dt {
          font-size: 18px;
          font-weight: 500;
          margin: 30px 0 5px;
        }
        dd {
          font-size: 16px;
        }
        @media (${Variables.breakPoint.sp}) {
          dt {
            font-size: 15px;
          }
          dd {
            font-size: 15px;
          }
        }
      `}</style>
      <div>
        <dt>{title}</dt>
        <dd>{content}</dd>
      </div>
    </>
  );
};
