import * as React from "react";
import { Color, Variables } from "../designToken";
interface Props {
  title: string;
  description?: any;
}
const PageHeading: React.FC<Props> = ({ title, description }) => {
  return (
    <>
      <style jsx>{`
        .section {
          padding: 0;
        }
        .section .sectionInner {
          padding: 150px 0 0;
          max-width: 900px;
        }
        h1 {
          font-size: 86px;
          color: ${Color.brand};
          font-weight: 500;
          border-bottom: solid 4px ${Color.brand};
          display: inline-block;
          line-height: 1.15;
        }
        p {
          font-size: 15px;
          margin: 30px 0;
        }
        @media (${Variables.breakPoint.sp}) {
          .sectionInner {
            padding: 110px 0 0;
            text-align: center;
          }
          h1 {
            font-size: 64px;
          }
          .section {
            padding: 0;
          }
          .section .sectionInner {
            padding: 100px 0 0;
          }
          p {
            padding: 0 20px;
            text-align: left;
          }
        }
      `}</style>
      <section className="section pageHeading">
        <div className="sectionInner">
          <h1>{title}</h1>
          {description && <p>{description}</p>}
        </div>
      </section>
    </>
  );
};

export default PageHeading;
