import React from "react";
import { Layout } from "../../components/layout";
import PageHeading from "../../components/subPageParts/pageHeading";
import SecitonBody from "../../components/subPageParts/sectionBody";
import { Label } from "../../components/contact/label";
import { InputText } from "../../components/contact/inputText";
import { InputTextArea } from "../../components/contact/inputTextArea";
import { Checkbox } from "../../components/contact/checkbox";
import { FormButton } from "../../components/contact/formButton";
import { ConfirmItem } from "../../components/contact/confirmItem";
import { Variables, Color } from "../../components/designToken";
import { ErrorMessage } from "../../components/contact/errorMessage";
import { ExternalLink } from "../../atoms/ExternalLink";

const CONTACT_TYPE_PREFIX = "contactType";
const CONTACT_TYPE_TEXT = {
  MEDIA: "取材・プレスに関するお問い合わせ",
  AGENCY: "代理店（アライアンスパートナー）に関するお問い合わせ",
  OTHER: "その他お問い合わせ",
};
enum ContactType {
  Media = "MEDIA",
  Agency = "AGENCY",
  Other = "OTHER",
}
const FORMSPREE_URL = "https://formspree.io/";
const FORM_ACTION = {
  MEDIA: "mjvepdgy",
  AGENCY: "meqlvgnn",
  OTHER: "mdolyzne",
};

interface State {
  value: {
    name: string;
    company: string;
    mail: string;
    tel: string;
    type: ContactType;
    mainText: string;
    policy: boolean;
  };
  message: {
    name: string;
    company: string;
    mail: string;
    tel: string;
    type: string;
    mainText: string;
    policy: string;
  };
  status: {
    name: boolean;
    company: boolean;
    mail: boolean;
    tel: boolean;
    type: boolean;
    mainText: boolean;
    policy: boolean;
  };
  activePage: string;
}

const lineFeedRegex = /([\n])/g;

export default class InputPage extends React.Component<any, State> {
  constructor(props) {
    super(props);

    this.state = {
      value: {
        name: "",
        company: "",
        mail: "",
        tel: "",
        type: ContactType.Media,
        mainText: "",
        policy: false,
      },
      message: {
        name: "",
        company: "",
        mail: "",
        tel: "",
        type: "",
        mainText: "",
        policy: "",
      },
      status: {
        name: false,
        company: false,
        mail: false,
        tel: false,
        type: false,
        mainText: false,
        policy: false,
      },
      activePage: "form",
    };
  }
  formRef = React.createRef<HTMLFormElement>();

  getTextAreaNewLine() {
    return this.state.value.mainText.split(lineFeedRegex).map((line, i) => {
      // eslint-disable-next-line react/no-array-index-key
      const newLine = line.match(lineFeedRegex) ? <br key={i} /> : line;
      return newLine;
    });
  }

  handleSetValue(key: string, value: string, message: string, status: boolean) {
    this.setState((prevState) => {
      prevState.value[key] = value;
      prevState.message[key] = message;
      prevState.status[key] = status;

      return prevState;
    });
  }

  toForm() {
    this.setState({ activePage: "form" });
  }

  toConfirm() {
    const form = this.formRef.current;
    if (!form.reportValidity()) {
      return;
    }
    if (!this.state.value.policy) {
      this.setState((prevState) => {
        prevState.message.policy = ErrorMessage.policy;
        prevState.status.policy = true;
        return prevState;
      });
      return;
    }
    this.setState({ activePage: "confirm" });
  }

  submit(e) {
    e.target.disabled = true;

    const form = this.formRef.current;
    const policy = form.querySelector("input[name=policy]");

    policy.parentNode.removeChild(policy);
    form.action = `${FORMSPREE_URL}${FORM_ACTION[this.state.value.type.toUpperCase()]}`;
    form.submit();
  }

  render() {
    return (
      <>
        <style jsx>{`
          #sectionBody :global(.section .sectionInner) {
            max-width: 640px;
          }
          .headWrapper :global(.pageHeading) {
            max-width: 640px;
            margin: 0 auto;
          }
          #fs-frm-inputs {
            border: none;
          }
          .inputWrapper {
            width: 100%;
            margin: 30px 0;
          }
          .inputWrapper:nth-child(1) {
            margin-top: 0;
          }
          .inputWrapper.center {
            text-align: center;
          }
          .inputWrapper :global(.btn.confirm .subTitle) {
            color: ${Color.lightText};
          }
          .inputWrapper :global(.acceptanceLink) {
            color: ${Color.defaultText};
            text-decoration: underline;
          }
          .marginBottom {
            margin-bottom: 50px;
          }
          dl :global(dt:nth-child(1)) {
            margin-top: 0;
          }
          .inputRadioWrapper {
            margin: 4px 0;
          }
          .inputRadioWrapper input[type="radio"] {
            display: none;
          }
          .inputRadioWrapper label {
            cursor: pointer;
          }
          .inputRadioWrapper label .inputRadioCircle {
            display: inline-block;
            margin-right: 5px;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            border: solid 1px ${Color.defaultText};
            vertical-align: middle;
            position: relative;
          }
          .inputRadioWrapper input[type="radio"]:checked + label .inputRadioCircle::after {
            content: "";
            width: 8px;
            height: 8px;
            background-color: ${Color.defaultText};
            border-radius: 50%;
            position: absolute;
            top: 2px;
            left: 2px;
          }
          .inputRadioWrapper label span {
            vertical-align: middle;
          }
          .buttonWrapper {
            display: flex;
            justify-content: space-between;
            text-align: center;
            margin-top: 50px;
          }
          .buttonWrapper :global(button):first-child {
            border: solid 2px ${Color.lightText};
            color: ${Color.lightText};
          }
          .buttonWrapper :global(button):before,
          .buttonWrapper :global(button):after {
            display: none;
          }
          form {
            display: ${this.state.activePage === "form" ? "block" : "none"};
          }
          .confirm {
            display: ${this.state.activePage === "confirm" ? "block" : "none"};
          }
          @media (${Variables.breakPoint.sp}) {
            #sectionBody :global(.sectionInner) {
              padding-top: 0;
            }
            .buttonWrapper {
              display: block;
              text-align: center;
            }
            .buttonWrapper :global(button) {
              margin: 10px;
            }
          }
        `}</style>
        <Layout>
          <div className="headWrapper">
            <PageHeading
              title="contact"
              description={
                (this.state.activePage === "form" && (
                  <>
                    営業目的でのお問合わせはご遠慮ください。
                    <br />
                    すでに当社サービスを利用中の企業の方は、当社担当まで直接、お問い合わせください。
                  </>
                )) ||
                `以下の内容でよろしければ、送信ボタンを押してください。`
              }
            />
          </div>
          <div id="sectionBody">
            <SecitonBody>
              <form id="fs-frm" name="simple-contact-form" acceptCharset="utf-8" method="post" ref={this.formRef}>
                <fieldset id="fs-frm-inputs">
                  <div className="inputWrapper">
                    <Label text="氏名" isRequired htmlFor="name" />
                    <InputText
                      setValue={(key, value, message, status) => {
                        this.handleSetValue(key, value, message, status);
                      }}
                      nameAttr="name"
                      placeholder="山田太郎"
                      value={this.state.value.name}
                      message={this.state.message.name}
                      status={this.state.status.name}
                      validationTypes={{ required: true }}
                      type="text"
                    />
                  </div>
                  <div className="inputWrapper">
                    <Label text="会社名/部署名" isRequired htmlFor="company" />
                    <InputText
                      setValue={(key, value, message, status) => {
                        this.handleSetValue(key, value, message, status);
                      }}
                      nameAttr="company"
                      placeholder="株式会社リフカム マーケティング部"
                      value={this.state.value.company}
                      message={this.state.message.company}
                      status={this.state.status.company}
                      validationTypes={{ required: true }}
                      type="text"
                    />
                  </div>
                  <div className="inputWrapper">
                    <Label text="メールアドレス" isRequired htmlFor="mail" />
                    <InputText
                      setValue={(key, value, message, status) => {
                        this.handleSetValue(key, value, message, status);
                      }}
                      nameAttr="mail"
                      placeholder="pr@refcome.com"
                      value={this.state.value.mail}
                      message={this.state.message.mail}
                      status={this.state.status.mail}
                      validationTypes={{ required: true, mail: true }}
                      type="email"
                    />
                  </div>
                  <div className="inputWrapper">
                    <Label text="電話番号" isRequired={false} htmlFor="tel" />
                    <InputText
                      type="tel"
                      setValue={(key, value, message, status) => {
                        this.handleSetValue(key, value, message, status);
                      }}
                      nameAttr="tel"
                      placeholder="03-5656-9966"
                      value={this.state.value.tel}
                      message={this.state.message.tel}
                      status={this.state.status.tel}
                      validationTypes={{}}
                    />
                  </div>
                  <div className="inputWrapper">
                    <Label text="お問い合わせ区分" isRequired />
                    <div className="inputRadioWrapper">
                      <input
                        type="radio"
                        name="type"
                        value={ContactType.Media}
                        id={`${CONTACT_TYPE_PREFIX}_${ContactType.Media}`}
                        onChange={() => this.handleSetValue("type", ContactType.Media, "", false)}
                        checked={ContactType.Media === this.state.value.type}
                      />
                      <label htmlFor={`${CONTACT_TYPE_PREFIX}_${ContactType.Media}`}>
                        <div className="inputRadioCircle" />
                        <span>{CONTACT_TYPE_TEXT[ContactType.Media]}</span>
                      </label>
                    </div>
                    <div className="inputRadioWrapper">
                      <input
                        type="radio"
                        name="type"
                        value={ContactType.Agency}
                        id={`${CONTACT_TYPE_PREFIX}_${ContactType.Agency}`}
                        onChange={() => this.handleSetValue("type", ContactType.Agency, "", false)}
                        checked={ContactType.Agency === this.state.value.type}
                      />
                      <label htmlFor={`${CONTACT_TYPE_PREFIX}_${ContactType.Agency}`}>
                        <div className="inputRadioCircle" />
                        <span>{CONTACT_TYPE_TEXT[ContactType.Agency]}</span>
                      </label>
                    </div>
                    <div className="inputRadioWrapper">
                      <input
                        type="radio"
                        name="type"
                        value={ContactType.Other}
                        id={`${CONTACT_TYPE_PREFIX}_${ContactType.Other}`}
                        onChange={() => this.handleSetValue("type", ContactType.Other, "", false)}
                        checked={ContactType.Other === this.state.value.type}
                      />
                      <label htmlFor={`${CONTACT_TYPE_PREFIX}_${ContactType.Other}`}>
                        <div className="inputRadioCircle" />
                        <span>{CONTACT_TYPE_TEXT[ContactType.Other]}</span>
                      </label>
                    </div>
                  </div>
                  <div className="inputWrapper marginBottom">
                    <Label text="お問い合わせ内容" isRequired htmlFor="mainText" />
                    <InputTextArea
                      setValue={(key, value, message, status) => {
                        this.handleSetValue(key, value, message, status);
                      }}
                      nameAttr="mainText"
                      placeholder="ご記入ください"
                      value={this.state.value.mainText}
                      message={this.state.message.mainText}
                      status={this.state.status.mainText}
                      validationTypes={{ required: true }}
                    />
                  </div>
                  <div className="inputWrapper center">
                    <Checkbox
                      setValue={(key, value, message, status) => {
                        this.handleSetValue(key, value, message, status);
                      }}
                      nameAttr="policy"
                      value={this.state.value.policy}
                      message={this.state.message.policy}
                      status={this.state.status.policy}
                      validationTypes={{ required: true }}
                      labelText={
                        <>
                          <ExternalLink className="acceptanceLink" to="https://jp.refcome.com/privacy/statement" target="_blank">
                            プライバシーポリシー
                          </ExternalLink>
                          と
                          <ExternalLink
                            className="acceptanceLink"
                            to="https://jp.refcome.com/privacy"
                            target="_blank"
                          >
                            個人情報のお取り扱い
                          </ExternalLink>
                          に同意する
                        </>
                      }
                    />
                  </div>
                  <div className="inputWrapper center">
                    <FormButton
                      text="Confirm"
                      subText="入力内容を確認する"
                      onClick={(e) => {
                        this.toConfirm(e);
                      }}
                      className="confirm"
                    />
                  </div>
                  <input type="hidden" name="_language" value="ja" />
                  <input type="hidden" name="_next" value="https://about.refcome.com/contact/thanks" />
                </fieldset>
              </form>
              <div className="confirm">
                <dl>
                  <ConfirmItem title="氏名" content={this.state.value.name} />
                  <ConfirmItem title="会社名/部署名" content={this.state.value.company} />
                  <ConfirmItem title="メールアドレス" content={this.state.value.mail} />
                  <ConfirmItem title="電話番号" content={this.state.value.tel} />
                  <ConfirmItem title="お問い合わせ区分" content={CONTACT_TYPE_TEXT[this.state.value.type]} />
                  <ConfirmItem title="お問い合わせ内容" content={this.getTextAreaNewLine()} />
                </dl>
                <div className="buttonWrapper">
                  <FormButton text="Back" subText="入力画面に戻る" onClick={(e) => this.toForm(e)} />
                  <FormButton text="Submit" subText="送信する" onClick={(e) => this.submit(e)} />
                </div>
              </div>
            </SecitonBody>
          </div>
        </Layout>
      </>
    );
  }
}
