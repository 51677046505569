import * as React from "react";
import { Color } from "../designToken";

interface Props {
  text: string;
}
export const Error: React.FC<Props> = ({ text }) => {
  return (
    <>
      <style jsx>{`
        .error {
          display: block;
          color: ${Color.brand};
          font-size: 14px;
          margin-top: 5px;
        }
      `}</style>
      <span className="error">{text}</span>
    </>
  );
};
