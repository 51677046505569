import * as React from "react";
import { Color, Variables } from "../designToken";
import rightArrow from "../../images/inline_svg/rightArrow.svg";

interface Props {
  text: string;
  subText?: string;
  onClick: any;
  className?: string;
}
export const FormButton: React.FC<Props> = ({ text, subText, onClick, className }) => {
  return (
    <>
      <style jsx>{`
        .btn {
          position: relative;
          display: inline-block;
          text-align: center;
          padding: 15px 80px;
          border-radius: 50px;
          font-size: 13px;
          color: ${Color.brand};
          transition: opacity 0.3s ease;
          border: solid 1px ${Color.brand};
        }
        .btn:after {
          position: absolute;
          display: block;
          right: 10px;
          top: 0;
          bottom: 0;
          margin: auto;
          content: "";
          width: 24px;
          height: 24px;
          background-image: url(${rightArrow});
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }
        .btn .title {
          position: relative;
          display: block;
          font-size: 20px;
          line-height: 1.5;
          z-index: 2;
          font-weight: 500;
        }
        .btn .subTitle {
          position: relative;
          display: block;
          z-index: 2;
          font-size: 13px;
        }
        .btn:hover {
          opacity: 0.7;
        }
        @media (${Variables.breakPoint.sp}) {
          .btn {
            padding: 12px 60px;
            font-size: 12px;
          }
          .btn .title {
            font-size: 20px;
          }
          .btn img {
            width: 96px;
          }
        }
      `}</style>
      <button type="button" className={`btn ${className || ""}`} onClick={(e) => onClick(e)}>
        <span className="title">{text}</span>
        {subText && <span className="subTitle">{subText}</span>}
      </button>
    </>
  );
};
