import * as React from "react";
import { Variables } from "../designToken";

interface Props {
  text: string;
  isRequired: boolean;
  htmlFor?: string;
}
export const Label: React.FC<Props> = ({ text, isRequired, htmlFor }) => {
  return (
    <>
      <style jsx>{`
        label {
          font-weight: 500;
          font-size: 18px;
          margin-bottom: 10px;
          display: block;
        }
        .require {
          font-size: 14px;
        }
        @media (${Variables.breakPoint.sp}) {
          label,
          .require {
            font-size: 15px;
          }
          label {
            margin-bottom: 5px;
          }
        }
      `}</style>
      <label htmlFor={htmlFor}>
        {text}
        {isRequired && <span className="require">（必須）</span>}
      </label>
    </>
  );
};
